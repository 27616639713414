import Container from "../../../../components/Container";
import {ReactComponent as CoinbaseIcon} from "../../../../assets/Coinbase_Wordmark_White.svg";
import {ReactComponent as MercyCorps} from "../../../../assets/Mercy-Corps.svg";

export default function InvestorsSection() {

  return (
    <div className="w-full bg-midnight text-white">
      <Container>
        <h3 className="text-center text-4xl md:text-5xl text-white mb-4">Our Investors</h3>
        <div className="w-full grid grid-cols-2 md:grid-cols-4 justify-center items-center gap-10 md:gap-20 mt-10">
          <img src="/assets/500-transparent.png" className="mx-auto w-32 md:w-44" alt="" />
          <img src="/assets/Avalanche_Horizontal_White.png" className="mx-auto w-32 md:w-44" alt="" />
          <CoinbaseIcon className="mx-auto w-32 md:w-44" />
          <MercyCorps className="mx-auto w-32 md:w-44" />
        </div>
      </Container>
    </div>
  );
}