import Logo from "../Logo";
import Menu from "../Menu";

export default function Topbar() {
  return (
    <div className="w-full h-14 bg-midnight flex justify-center border-b border-gray-700">
      <div className="container w-5/6 md:w-2/3 text-white mx-auto flex flex-row space-x-2 items-center justify-between">
        <Logo />
        <Menu />
      </div>
    </div>
  );
}
