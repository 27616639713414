import { ReactComponent as CoinbaseIcon } from '../../../../assets/Coinbase_Wordmark_White.svg';
import { ReactComponent as MercyCorps } from '../../../../assets/Mercy-Corps.svg';
import { ReactComponent as LogoIcon } from '../../../../assets/umoja-badge.svg';
import Container from "../../../../components/Container";

export default function HeaderSection() {
  return (
    <div className="w-full bg-midnight text-white bg-cover bg-[url('/public/assets/dark-waves.png')]">
      <Container>
        <LogoIcon className="w-24 mx-auto mb-10" />
        <h1 className="text-3xl md:text-6xl leading-tight">Everything you need to connect crypto and emerging markets</h1>
        <p className="text-base flex-wrap text-white md:text-gray-500 m-5">The API for the next billion wallet holders.</p>
        <a href="https://wkwbfgrwreg.typeform.com/to/Ov4kCh3C" target="_blank">
          <button className="bg-purple hover:bg-indigo-700 text-white text-sm py-2 px-12 rounded">
            Talk to us
          </button>
        </a>
        <img src="/assets/hero-dashboard.png" className="w-full " alt="" />
        <p className="text-base text-white mt-16">Backed by the brightest minds in the world</p>
        <div className="w-5/6 md:w-full mx-auto grid grid-cols-2 md:grid-cols-4 justify-center items-center gap-20 mt-10">
          <img src="/assets/500-transparent.png" className="flex-1 w-44" alt="" />
          <img src="/assets/Avalanche_Horizontal_White.png" className="flex-1 w-44" alt="" />
          <CoinbaseIcon className="flex-1 w-44" />
          <MercyCorps className="flex-1 w-44" />
        </div>
      </Container>
    </div>
  );
}