import { ReactComponent as LogoIcon } from '../../assets/umoja-header.svg';

export default function Logo() {

  return (
    <div className="w-24">
      <LogoIcon />
    </div>
  )
}
