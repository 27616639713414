import Layout from "../../components/Layout";
import React from "react";
import Container from "../../components/Container";

export default function TermsScreen() {
  return (
    <Layout>
      <>
        <div className="w-full bg-midnight text-white">
          <Container>
            <div>
              <h1 className="text-6xl leading-tight max-w-3xl mx-auto mb-12">Terms of Service</h1>
              <p className="text-2xl text-white mt-5 max-w-lg mx-auto uppercase">Umoja labs group corp.</p>
              <p className="text-base text-white my-2 max-w-lg mx-auto">Effective Date: January 12, 2023</p>
              <div className="w-2/3 mx-auto text-gray-400 text-base text-left mt-10">
                <p className="mb-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vulputate venenatis dignissim. Donec scelerisque hendrerit felis id mollis. Maecenas vestibulum massa id ipsum cursus, non commodo sem imperdiet. Sed vitae sagittis magna. In hac habitasse platea dictumst. Suspendisse potenti. Sed ut urna feugiat, ullamcorper sem nec, auctor sem. Maecenas varius velit sed sagittis venenatis.</p>

                <p className="my-10">Curabitur sollicitudin lectus eget mi rhoncus, eu dapibus nibh rutrum. Nulla auctor tincidunt tincidunt. Donec vel volutpat tortor, eu suscipit est. Phasellus erat lectus, ullamcorper eget lectus ut, laoreet condimentum lectus. Proin eget ligula vitae libero egestas cursus. Aliquam rutrum ante non ante pretium, consectetur dignissim enim luctus. Proin bibendum elementum enim ac laoreet. Phasellus in fermentum nunc. Praesent mollis mauris vel massa accumsan, et blandit mauris eleifend. Mauris id est purus.</p>

                <p className="my-10">Praesent vitae nisi vel dolor gravida pellentesque. Integer a felis orci. Proin accumsan tristique velit eu viverra. Maecenas id enim dapibus, fermentum ante at, maximus justo. In id tempor purus. Donec vulputate arcu quis blandit ullamcorper. Etiam aliquam dui eu nisl faucibus imperdiet. In posuere maximus urna at pretium. Quisque eu justo id ipsum porttitor tempor. Etiam in enim velit. Integer hendrerit vulputate nisl, a fermentum nulla elementum pretium. Curabitur porta metus efficitur nisi fringilla, non condimentum erat semper. Sed sit amet libero vel eros malesuada sollicitudin.</p>

                <p className="my-10">Aenean tellus lacus, ultrices eget enim ut, molestie gravida urna. Duis eget orci eu tortor bibendum rhoncus nec consectetur velit. Integer in lacinia justo. Praesent vitae mi ac ligula lacinia bibendum. Integer quam nisi, convallis sit amet justo eu, sodales dapibus dolor. Donec eget ligula arcu. Ut id elementum turpis, nec interdum risus. Maecenas ornare urna sit amet mauris efficitur, at tincidunt erat luctus. Nam suscipit magna sit amet justo dapibus, sit amet fringilla arcu semper. Interdum et malesuada fames ac ante ipsum primis in faucibus. Suspendisse potenti. Proin pulvinar quis diam sed viverra. Integer posuere nibh eros, vel feugiat justo imperdiet ut. Nulla lobortis tincidunt sapien a varius.</p>

                <p className="mt-10">Sed vitae nisl consectetur, faucibus odio vel, facilisis magna. Morbi ac massa ut nisl sodales finibus sed quis est. Suspendisse laoreet consectetur scelerisque. Quisque vitae urna tincidunt, facilisis urna at, luctus enim. Cras semper libero et magna fermentum, eget congue nunc maximus. Aliquam massa erat, dapibus lacinia viverra sit amet, hendrerit nec ipsum. Integer in sem ut eros consequat scelerisque eu a augue. Integer semper sollicitudin magna nec maximus. Aenean nec efficitur dui. Nunc tellus libero, tristique eu sagittis eu, ornare fringilla nunc. In tristique fermentum tortor ac faucibus.</p>
              </div>
            </div>
          </Container>
        </div>
      </>
    </Layout>
  )
}
