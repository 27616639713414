import React from 'react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import LandingScreen from "./pages/Landing";
import AboutUsScreen from "./pages/AboutUs";
import TermsScreen from "./pages/Terms";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingScreen />,
  },
  {
    path: "/about-us",
    element: <AboutUsScreen />,
  },
  {
    path: '/terms',
    element: <TermsScreen />,
  }
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
