import React from "react";
import Topbar from "../Topbar";
import Footer from "../Footer";

export default function Layout({ children }: any) {

  return (
    <div className="w-full">
      <Topbar />
      <div className="w-full">
        {children}
      </div>
      <Footer />
    </div>
  );
}
