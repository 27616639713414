import {Link} from "react-router-dom";

export default function Menu() {
  return (
    <nav className="w-3/6 md:w-2/6">
      <ul className="grid grid-flow-col grid-cols-max text-sm gap-2">
        <li>
          <a href="/#api" className="text-white hover:text-gray-400">API</a>
        </li>
        <li>
          <a href="/#widget" className="text-white hover:text-gray-400">Widget</a>
        </li>
        <li>
          <a href="https://umoja.finance/" target="_blank" className="text-white hover:text-gray-400">Protocol</a>
        </li>
        <li>
          <Link to={"/about-us"} className="text-white hover:text-gray-400">Company</Link>
        </li>
      </ul>
    </nav>
  )
}
