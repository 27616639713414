import Container from "../Container";
import { ReactComponent as LogoIcon } from '../../assets/umoja-footer.svg';
import {Link} from "react-router-dom";

export default function Footer() {

  return (
    <div className="w-full bg-black text-white">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="mx-auto w-5/6 md:w-full flex flex-col text-left">
            <LogoIcon className="w-32" />
            <p className="text-sm text-gray-500 mt-5 ">
              &copy; {new Date().getFullYear()} Umoja Labs. All rights reserved.
            </p>
          </div>
          <div className="mx-auto w-5/6 md:w-full grid grid-cols-3 gap-2">
            <div className="w-full">
              <h4 className="text-2xl font-bold text-left mb-5">Product</h4>
              <ul className="text-left text-gray-400 text-sm grid grid-rows-3 gap-1.5">
                <li><a className="hover:underline" href="/#api">Umoja Money API</a></li>
                <li><a className="hover:underline" href="https://umoja.finance/">Protocol</a></li>
                <li><a className="hover:underline" href="/#widget">Widget</a></li>
              </ul>
            </div>
            {/*<div className="w-full">*/}
            {/*  <h4 className="text-2xl font-bold text-left mb-5">Resources</h4>*/}
            {/*  <ul className="text-left text-gray-400 text-sm grid grid-rows-3 gap-1.5">*/}
            {/*    /!*<li><a className="hover:underline" href="#">Blog</a></li>*!/*/}
            {/*    /!*<li><a className="hover:underline" href="#">FAQs</a></li>*!/*/}
            {/*    /!*<li><a className="hover:underline" href="#">Docs</a></li>*!/*/}
            {/*    <li><Link className="hover:underline" to="/terms">Terms</Link></li>*/}
            {/*    <li><Link className="hover:underline" to="/terms">Privacy</Link></li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
            <div className="w-full">
              <h4 className="text-2xl font-bold text-left mb-5">Company</h4>
              <ul className="text-left text-gray-400 text-sm grid grid-rows-3 gap-1.5">
                <li><Link className="hover:underline" to="/about-us">About</Link></li>
                {/*<li><Link className="hover:underline" to="/about-us">Jobs</Link></li>*/}
                {/*<li><Link className="hover:underline" to="/about-us">Press Kit</Link></li>*/}
                <li><a className="hover:underline" href="https://twitter.com/UmojaLabs" target="_blank">Twitter</a></li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

