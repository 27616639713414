import Container from "../../../../components/Container";

export default function HeroSection() {
  return (
    <div className="w-full h-screen bg-midnight text-white bg-cover bg-[url('/public/assets/waves-reverse.png')]">
      <Container>
        <h1 className="text-3xl md:text-6xl leading-tight max-w-3xl mx-auto">Blockchain infrastructure powering financial
          inclusion</h1>
        <p className="text-base text-gray-300 m-5 max-w-lg mx-auto">Umoja Labs builds proprietary and open-source
          blockchain infrastructure to power financial & economic inclusion</p>
        <div className="w-5/6  md:w-full max-w-4xl grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10 justify-center mx-auto mt-24">
          <div className="w-full rounded bg-gray-900 p-10">
            <h2 className="text-2xl text-left" id="protocol">Umoja Protocol</h2>
            <p className="text-sm text-gray-400 text-left mt-6">Umoja Protocol is a decentralized finance marketplace
              for real-world, impact investment.</p>
            <div className="flex flex-row gap-3 mt-10 justify-start">
              <a href="https://wkwbfgrwreg.typeform.com/to/Ov4kCh3C" target="_blank">
                <button className="w-full bg-purple hover:bg-indigo-700 text-white text-sm py-2 px-12 rounded">
                  Learn more
                </button>
              </a>
              {/*<a href="https://wkwbfgrwreg.typeform.com/to/Ov4kCh3C" target="_blank">*/}
              {/*  <button className="w-full bg-white hover:bg-gray-200 text-black text-sm py-2 px-12 rounded">*/}
              {/*    White Paper*/}
              {/*  </button>*/}
              {/*</a>*/}
            </div>
          </div>
          <div className="w-full rounded bg-gray-900 p-10">
            <h2 className="text-2xl text-left">Umoja Money API</h2>
            <p className="text-sm text-gray-400 text-left mt-6">Umoja Money API is a crypto-to-fiat on/off-ramp for
              emerging market payment channels.</p>
            <div className="flex flex-row justify-start mt-10">
              <a href="https://wkwbfgrwreg.typeform.com/to/Ov4kCh3C" target="_blank">
                <button className="w-full bg-purple hover:bg-indigo-700 text-white text-sm py-2 px-12 rounded">
                  Learn more
                </button>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}