import { ReactComponent as PlugIcon } from '../../../../assets/plug.svg';
import { ReactComponent as ExtendIcon } from '../../../../assets/extend.svg';
import { ReactComponent as WorldIcon } from '../../../../assets/localize.svg';
import { ReactComponent as SettingsIcon } from '../../../../assets/manage.svg';
import Container from "../../../../components/Container";


export default function WidgetSection() {
  return (
    <div id="widget" className="w-full bg-dark-purple text-white">
      <Container >
        <span className="text-light-green text-xl tracking-widest">Coming Soon</span>
        <h1 className="text-5xl leading-tight mt-5">Umoja Money No-code Widget</h1>
        <p className="text-base text-gray-400 m-5 w-5/6 md:w-3/5 mx-auto tracking-wider">
          The embeddable solution for your on/off-ramp needs. Simple and secure user experiences for buying and selling stablecoins in any application.
        </p>
        <a href="https://wkwbfgrwreg.typeform.com/to/Ov4kCh3C" target="_blank">
          <button className="bg-light-green hover:bg-emerald-400 text-black text-sm py-3 px-10 mt-4 rounded">
            Request Access
          </button>
        </a>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-32 mt-10">
          <div className="w-full flex justify-center items-center">
            <img src="/assets/no-code-widget.png" className="w-5/6" alt="" />
          </div>
          <div className="w-full px-6 py-10 gap-2">
            <div className="grid grid-cols-2 grid-rows-2 gap-10">
              <div className="w-full flex flex-col gap-2">
                <PlugIcon className="fill-light-green " />
                <h4 className="font-normal text-base text-left tracking-wider">Plug & Play</h4>
                <p className="font-normal text-gray-400 text-sm text-left tracking-tight">Simple, secure and built to convert new customers in new markets.</p>
              </div>
              <div className="w-full flex flex-col gap-2">
                <ExtendIcon className="fill-light-green " />
                <h4 className="font-normal text-base text-left tracking-wider">Extensible</h4>
                <p className="font-normal text-gray-400 text-sm text-left tracking-tight">White-label API for customizable modules and user flows.</p>
              </div>
              <div className="w-full flex flex-col gap-2">
                <WorldIcon className="fill-light-green " />
                <h4 className="font-normal text-base text-left tracking-wider">Global Coverage</h4>
                <p className="font-normal text-gray-400 text-sm text-left tracking-tight">Global deposit and withdrawal coverage across payment methods.</p>
              </div>
              <div className="w-full flex flex-col gap-2">
                <SettingsIcon className="fill-light-green " />
                <h4 className="font-normal text-base text-left tracking-wider">Dead simple management</h4>
                <p className="font-normal text-gray-400 text-sm text-left tracking-tight">Access a single dashboard for all of your API instances</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}