import Container from "../../../../components/Container";

export default function MonitorSection() {
  return (
    <div className="w-full bg-gradient-to-r from-dark-purple to-light-purple text-white">
      <Container>
        <span className="text-white text-xl tracking-widest">Frictionless setup</span>
        <h1 className="text-5xl leading-tight mt-5">Monitor transactions & wallet activity</h1>
        <p className="text-base text-gray-400 m-5 w-4/5 mx-auto tracking-wider">
          Start onboarding customers in emerging markets with just a few lines of code.<br /> Manage transactions, disputes, and rate limits from a single dashboard
        </p>
        <div className="w-full flex justify-center items-center">
          <img src="/assets/monitor-dashboard.png" className="w-full" alt="" />
        </div>
        <div className="w-1/2 mx-auto">
          {/*<button className="bg-white hover:bg-gray-200 text-black text-sm py-3 px-10 rounded">*/}
          {/*  Request API Keys*/}
          {/*</button>*/}
          <a href="https://wkwbfgrwreg.typeform.com/to/Ov4kCh3C" target="_blank">
            <button className="bg-gray-900 hover:bg-gray-800 text-white text-sm py-2 px-12 rounded">
              Book a Demo
            </button>
          </a>
        </div>
      </Container>
    </div>
  );
}