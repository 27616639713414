import Layout from "../../components/Layout";
import React from "react";
import HeroSection from "./components/HeroSection";
import NewsSection from "./components/NewsSection";
import TeamSection from "./components/TeamSection";
import InvestorsSection from "./components/InvestorsSection";

export default function AboutUsScreen() {
  return (
    <Layout>
      <>
        <HeroSection />
        {/*<NewsSection />*/}
        {/*<TeamSection />*/}
        <InvestorsSection />
      </>
    </Layout>
  )
}
