import { FaCheck } from 'react-icons/fa';
import Container from "../../../../components/Container";

export default function PricingSection() {
  return (
    <div className="w-full bg-gray-100 text-black">
      <Container>
        <div className="w-5/6 mx-auto md:w-full grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-0 content-between">
          <div className="w-full max-w-md mx-auto">
            <h2 className="text-5xl text-left mt-10">Your customers' favorite payment methods.</h2>
            <p className="text-normal text-gray-600 text-left w-52 my-6">Increase revenue with the best conversions on the market.</p>
            <div className="w-full grid grid-cols-3 gap-4">
              <img src="/assets/payments/tigopay.png" className="w-full" alt="" />
              <img src="/assets/payments/mtn.png" className="w-full" alt="" />
              <img src="/assets/payments/mpesa.png" className="w-full" alt="" />
              <img src="/assets/payments/wise.png" className="w-full" alt="" />
              <img src="/assets/payments/usdc.png" className="w-full" alt="" />
            </div>
          </div>
          <div className="w-full">
            <div className="w-full h-full shadow-2xl rounded-lg bg-white p-10 max-w-md">
              <h2 className="text-5xl text-left mt-10">Scalable Pricing</h2>
              <p className="text-normal text-gray-600 text-left w-52 mt-5">Flexible and built to scale</p>
              <div className="w-full border-b border-gray-200 my-4"></div>
              <div className="w-full grid grid-cols-2">
                <div className="w-full">
                  <h4 className="text-normal text-black text-left mb-6">Supported Countries</h4>
                  <ul className="text-left">
                    <li className="flex flex-row items-center gap-3 my-2"><FaCheck className="text-blue-600" /> Kenya</li>
                    <li className="flex flex-row items-center gap-3 my-2"><FaCheck className="text-blue-600" /> Tanzania</li>
                    <li className="flex flex-row items-center gap-3 my-2"><FaCheck className="text-blue-600" /> Uganda</li>
                    <li className="flex flex-row items-center gap-3 my-2"><FaCheck className="text-blue-600" /> Nigeria</li>
                    <li className="flex flex-row items-center gap-3 my-2"><FaCheck className="text-blue-600" /> Rwanda</li>
                    <li className="flex flex-row items-center gap-3 my-2"><FaCheck className="text-blue-600" /> Ghana</li>
                  </ul>
                </div>
                <div className="w-full">
                  <h4 className="text-normal text-black text-left mb-6">Payment Methods</h4>
                  <ul className="text-left">
                    <li className="flex flex-row items-center gap-3 my-2"><FaCheck className="text-blue-600" /> Bank Accounts</li>
                    <li className="flex flex-row items-center gap-3 my-2"><FaCheck className="text-blue-600" /> Mobile Money</li>
                    <li className="flex flex-row items-center gap-3 my-2"><FaCheck className="text-blue-600" /> Wise</li>
                    <li className="flex flex-row items-center gap-3 my-2"><FaCheck className="text-blue-600" /> $USDC</li>
                    <li className="flex flex-row items-center gap-3 my-2"><FaCheck className="text-blue-600" /> Airtm</li>
                    <li className="flex flex-row items-center gap-3 my-2"><FaCheck className="text-blue-600" /> Debit/Credit Cards</li>
                  </ul>
                </div>
              </div>
              <div className="w-full my-4">
                {/*<a href="https://my.atlistmaps.com/map/76b3951e-a766-4836-81d7-a3fbdcfc65cc?share=true" target="_blank">*/}
                {/*  <button className="bg-gray-900 hover:bg-gray-800 text-white text-sm py-2 px-12 rounded">*/}
                {/*    See full coverage*/}
                {/*  </button>*/}
                {/*</a>*/}
                <a href="https://wkwbfgrwreg.typeform.com/to/Ov4kCh3C" target="_blank">
                  <button className="bg-purple hover:bg-indigo-700 text-white text-sm py-2 px-12 rounded">
                    Talk to us
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}