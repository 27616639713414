import Layout from "../../components/Layout";
import React from "react";
import HeaderSection from "./components/HeaderSection";
import APISection from "./components/ApiSection";
import WidgetSection from "./components/WidgetSection";
import MonitorSection from "./components/MonitorSection";
import PricingSection from "./components/PricingSection";

export default function LandingScreen() {
  return (
    <Layout>
      <>
        <HeaderSection />
        <WidgetSection />
        <APISection />
        <MonitorSection />
        <PricingSection />
      </>
    </Layout>
  )
}
